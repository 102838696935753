@use '~nbds-react/lib/styles/styles.scss';

// Theme

:root {

  // Background

  --nbds-background-dark: #252525;

  // Primary

  --nbds-primary-darker: #003d66;
  --nbds-primary-dark: #00497a;
  --nbds-primary-default: #00558c;
  --nbds-primary-light: #0062a3;
  --nbds-primary-lighter: #006eb8;
  --nbds-primary-lightest: #8bd9d7;

  // Secondary

  --nbds-secondary-darker: #1e5c27;
  --nbds-secondary-dark: #236c2e;
  --nbds-secondary-default: #2c863a;
  --nbds-secondary-light: #329a42;
  --nbds-secondary-lighter: #37a948;
  --nbds-secondary-lightest: #57e96d;

  // Message

  --nbds-error-dark: #e12d2d;
  --nbds-error-darker: #d61f1f;

  // Font

  --nbds-font-dark: #000;
  --nbds-font-light: #fff;

  // Footer

  --nbds-footer: var(--nbds-background-dark);

}

// Settings

:root {

  // Border Radius

  --nbds-border-radius: 0;
  
}

// Globals

body, html {
  height: 100%;
}

body {
  background-color: var(--nbds-background-dark);
}

// Overrides

.nbds-button .nbds-preloader {
  margin: 0 0.5rem 0 -2rem;

  div {
    border-top-color: var(--nbds-secondary-lighter);
  }
}

.nbds-form-field .nbds-form-field-label {
  color: var(--nbds-font-dark);
  text-transform: unset;
}

.nbds-form-field-dark.nbds-form-field-error .nbds-form-field-caption-text {
  color: var(--nbds-error-dark);
}
