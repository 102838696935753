@use '~nbds-react/lib/styles/breakpoints' as *;

.nbds-footer {
  padding: 1.5rem;

  .nbds-footer-slot-6 {
    margin: 0;
  }
}

.nbds-footer-copyright {
  display: flex;
  align-items: center;

  a {
    flex: none;
    width: 8rem;

    &:nth-of-type(n+2) {
      margin-left: 1rem;
    }
  }

  img {
    display: block;
  }

  p {
    font-size: var(--nbds-font-size-01);
  }
}

@media screen and (max-width: $nbds-bp-sm-max) {

  .nbds-footer-copyright {
    flex-wrap: wrap;
    justify-content: center;

    p {
      flex-basis: 100%;
      text-align: center;
    }
  }

}

@media screen and (min-width: $nbds-bp-md) {

  .nbds-footer-copyright {

    p {
      flex-grow: 1;
      margin-bottom: 0;
    }
  }

}
