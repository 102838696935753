@use '~nbds-react/lib/styles/breakpoints' as *;

div#root {
  background-color: var(--nbds-background-light);
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.app-main {
  flex-grow: 1;
}

.app-content {
  box-sizing: content-box;
  max-width: 90rem;
  margin: 0 auto;
  padding: 2rem;
}

@media screen and (min-width: $nbds-bp-md) {

  .app-content  {
    padding: 3rem;
  }

}

@media screen and (min-width: $nbds-bp-lg) {

  .app-content  {
    padding: 4rem;
  }

}
