@use '~nbds-react/lib/styles/breakpoints' as *;

.app-header {
  background-color: var(--nbds-primary-default);
  padding: 1.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

.app-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;

  .app-header-logo, img {
    display: block;
    width: 8rem;
  }

  .nbds-show-sidebar-menu-button {
    background-color: transparent;
    width: 2rem;
    height: 2rem;

    &:focus {
      outline: auto 0.3125rem -webkit-focus-ring-color;
    }

    svg {
      fill: #fff;
      margin: 0;
    }
  }
}

.app-header-user {
  border-top: 0.0625rem solid var(--nbds-neutral-default);
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

p.app-header-user-message {
  font-size: var(--nbds-font-size-02);
  font-weight: var(--nbds-primary-font-weight-03);
  margin: -0.375rem 0 1.125rem;
}
